.welcome-body{


    .welcome-container{
        background-color: #F5F5F7;
        padding: 20px;
        border-radius: 16px;

        h3{
            font-weight: 500;
        }
    }
}