@import "../../../Colors.scss";

.signup-carousel-body {
  width: 100vw;
  height: 100vh;
  background-color: $background;
  display: flex;
  position: relative;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  // overflow-x: scroll;
  .signup-carousel-list {
    display: flex;
    width: calc(100vw * 4);
    .signup-carousel-item {
      scroll-snap-align: start;
      height: 100vh;
      width: 100vw;
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
      // animation-name: signupscroll;
      // animation-duration: 1s;
      // animation-fill-mode: forwards;
      // animation-direction: ease-in-out;
    }
  }
}
