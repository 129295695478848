// $dark-color: #331687;
@import "../../../../Colors.scss";

.details-body {
    margin-top: 10px;
    height: fit-content;

    .details-heading {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .column {
            display: flex;
            flex-direction: column;

        }
    }


    .details-header {
        margin-top: 30px;
        p {
            color: gray;
        }
    }
}