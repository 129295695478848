.home-body{
    width: 100%;
    @media screen and (max-width: 800px) {
        margin-bottom: 50px;
    }
    
}
.home-row{
    display: flex;
    flex-direction: row;

    .home-column{
        display: flex;
        flex-direction: column;
    }
}
.left-side {
    flex: 60%; /* 70% width for the left side */
  }
  
  .right-side {
    flex: 30%; /* 30% width for the right side */
    margin-left: 30px;

    @media screen and (max-width: 1000px) {
        margin-left: 0px;
    }

    .toggle-button {
        background-color: #f4f4f4;
        border: 1px solid #ccc;
        border-radius: 20px;
        display: inline-block;
        cursor: pointer;
        padding: 5px 10px;
        transition: background-color 0.3s ease;
      
        &.active {
            background-color: #f4f4f4;
        }
      }
      
      .toggle-button:hover {
        background-color: #ccc;
      }
      
      .toggle-button.active:hover {
        background-color: #ccc;
      }
      


  }