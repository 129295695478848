@import "../../../Colors.scss";



.schedule-body {
  width: 100%;

  @media screen and (max-width: 800px) {
    margin-bottom: 50px;
  }
  .divider{
    background-color: #ccc;
    height: 0.5px;
    width: 100%;
    margin-bottom: 10px;
  }

  .current-date{
    display: flex;
    align-items: baseline;
    align-content: baseline;

    .current-month{
      font-size: 25px;
      
    }
    .current-year{
      font-size: 18px;
      color: grey;
      margin-left: 2px;
    }

  

    .group{
      display: flex;
      margin-left: 15px;

      .week-button{
        border: 0.5px solid #ccc;
        background: white;
        border-radius: 50%;
        margin-left: 5px;
      }
    }
  }





  .schedule-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;

    .schedule-day {
      height: 100%;
      width: 100%;
      display: flex;
      border: 0.5px solid #ccc;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
      border-radius: 8px;
      .header {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        padding: 20px;

        p{
          font-size: 20px;

        }

        span{
          color: gray;
        }

      }

      .classes-container {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        cursor: pointer;


        .class-body {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100px;
          border-radius: 8px;
          margin-bottom: 10px;
          padding: 20px;
          border: 0.5px solid #ccc;

          .content {
            display: flex;
            align-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;

            
            .trainer-photo {
              height: 60px;
              width: 60px;
              border-radius: 50%;
              background-color: $med-color;
              img {
                object-fit: cover;
              }
            }


            .class-data{
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              width: 100%;

              .class-header{
                display: flex;
                width: 100%;
                justify-content: space-between;

                h3{
                  font-size: 20px;
                }
              }


              .class-row{
                display: flex;
                align-items: center;
                align-content: center;

                .icon-item{
                  display: flex;
                  border-radius: 6px;
                  padding: 2px 5px;
                  p{
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    font-size: 11px;
                  }
                  i{
                    color: rgb(41, 41, 41);
                    margin-right: 3px;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    font-size: 11px;

                  }
                }

                .item-trainer{
                  border: 0.5px solid blue;
                  background-color: white;

                }
                .item-class-date{
                  border: 0.5px solid green;
                  background-color: white;
                }

                .item-duration{
                  border: 0.5px solid orange;
                  background-color: white;

                }


                .spacer{
                  margin: 0 5px;
                }
              }
            }

            i{
              margin-left: auto;
            }

          }
        }
      }
    }
  }
}
