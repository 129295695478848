@import "../../../Colors.scss";

.passkey-body {
  width: 100vw;
  height: 100vh;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  .passkey-section {
    width: 40vw;
    min-width: 200px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #passkey-magfit-logo{
      margin-bottom: 30px;
    }
    button{
      width: 100%;
      margin-top: 10px;
    }
    span, input{
      background-color: $background;
      border: none;
    }
  }
}
