.videos-body {
    margin-top: 10px;
    max-width: 100%;

    .media-heading {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;

        .column {
            display: flex;
            flex-direction: column;
        }

        .video-buttons {
            display: flex;
            flex-direction: row;

            a {
                text-decoration: none;
                color: white;
            }
        }
    }

    .videos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-top: 45px;
        .video {
            height: 420px;
            width: 275px;
            border-radius: 16px;
            margin-left: 25px;

            @media screen and (max-width: 800px) {
                margin-top: 25px;
                margin-left: 0px;
                width: 350px;
                height: 250px;
              }

            
            .video-player {
                height: 100%;
                width: 100%;
                border-radius: 16px;
                object-fit: cover;
                border: 1px solid #ccc;

            }
        }

        .ant-upload.ant-upload-drag {
            border-color: #ccc;
            width: 650px;
            height: 450px;

            @media screen and (max-width: 800px) {
                width: 350px;
                height: 450px;
              }

            &:hover{
                border-color: black;
                background-color: rgb(242,239,239,0.4);
            }

            .ant-upload-drag-icon {
                color: black;
                font-size: 55px;
                
            }

            .ant-upload-text {
                color: black;
                /* Change the text color */
            }

            .ant-upload-hint {
                color: grey;
                /* Change the hint text color */
            }
        }

    }
}