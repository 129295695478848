@import "../../../Colors.scss";

.passkey-body{


  .passkey-section{

    img{
      width: 400px;
      height: auto;

      @media only screen and (max-width: 800px) {
        width: 300px;
      }
    }

    span, input{
      background-color: $background;
      border: 1px solid $dark-color;
    }
  }
}

.success-body{
  text-align: center;
}