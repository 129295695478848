@import "../../../Colors.scss";

.signup-2-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .step-counter {
    margin-top: 5vh;
    width: 40vw;

    @media screen and (max-width: 800px) {
      width: 100vw;
      padding-left: 20px;
    }

    h1 {
      text-decoration: none;
    }

    p {
      color: gray;
    }

    .step {
      p {
        color: black;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .step-indicator {
        display: flex;
        margin-bottom: 15px;

        .step1 {
          width: 50px;
          height: 5px;
          background-color: black;
          margin-right: 10px;
          border-radius: 4px;
        }

        .step2 {
          width: 50px;
          height: 5px;
          background-color: black;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }

  .signup-2-form {
    width: 40vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    grid-template-columns: 10% 16% 16% 16% 6% 26% 10%;
    grid-template-rows: auto;
    grid-template-areas:
      ". gym-name gym-name gym-name . phone ."
      ". address address address . website ."
      ". state zip . . . .";
    background-color: #F5F5F7;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px 20px 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 800px) {
      width: 100vw;
      background-color: white;
      border: none;
    }

    #signup-gymname {
      grid-area: gym-name;
      margin: 0 auto;
      width: 100%;
    }

    #signup-address {
      grid-area: address;
      margin: 0 auto;
      width: 100%;

    }

    #signup-state {
      grid-area: state;
      margin-right: 5px;
    }

    #signup-zip {
      grid-area: zip;
      margin-left: 5px;
    }

    #signup-phone {
      grid-area: phone;
      margin: 0 auto;
      width: 100%;

    }

    #signup-website {
      grid-area: website;
      margin: 0 auto;
      width: 100%;

    }

    input {
      margin: 0 auto;
      width: 100%;
      border-radius: 8px;

    }

    .description {
      margin-bottom: 10px;
      h5 {
        max-width: 500px;
        min-width: 120px;
        text-decoration: none;
        margin: 0;
        padding: 0;

      }

      p {
        max-width: 500px;
        min-width: 120px;
        color: gray;
        font-size: 14px;
        margin: 0;
        padding: 0;

      }
    }
  }

  .save-dashboard-button {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    #save-button {
      width: 100%;
      margin: 0 auto;
    }
  }

  h4 {
    text-decoration: underline;
    width: 80vw;
    padding-bottom: 20px;
  }

  p {
    width: 80vw;
    padding-bottom: 20px;
  }

  hr {
    border-top: 2px solid black;
    width: 80vw;
  }

  .signup-time-row {
    display: flex;
    width: 70vw;
    justify-content: space-evenly;
    align-items: center;
    background-color: #f2efef;
    border-radius: 4px;
    padding-top: 20px;
    margin-bottom: 20px;

    h5 {
      width: 10%;
    }

    .signup-switch {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 34px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .signup-switch-slider {
        position: absolute;
        cursor: pointer;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: $light-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 5px;
          bottom: 4px;
          background-color: $background;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }

        &:after {
          content: "Closed";
          position: absolute;
          left: 35px;
          top: 4px;
        }
      }

      input:checked+.signup-switch-slider:before {
        -webkit-transform: translateX(65px);
        -ms-transform: translateX(65px);
        transform: translateX(65px);
      }

      input:checked+.signup-switch-slider:after {
        -webkit-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
        transform: translateX(-15px);
        content: "Open";
        color: white;
      }

      input:focus+.signup-switch-slider {
        box-shadow: 0 0 1px $dark-color;
      }

      input:checked+.signup-switch-slider {
        background-color: $dark-color;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .signup-2-form {
    width: 100vw !important;
    grid-template-areas:
      ". gym-name gym-name gym-name . phone ."
      ". address address address . website ."
      ". state state . zip zip ." !important;
  }

  .signup-time-row {
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid black;

    input+.hours-switch-slider {
      &::after {
        content: "CLOSED";
        position: relative;
        right: 70px;
        top: 5px;
      }
    }

    input:checked+.hours-switch-slider {
      &::after {
        content: "OPEN";
        left: 70px;
      }
    }

    h4,
    h5 {
      width: unset !important;
      font-size: 15px;
    }
  }
}