.plan-body{
    margin-top: 10px;
    
    .pricing-header{
        display: flex;
        flex-direction: column;

        h1{
            text-decoration: none;
        }
    }

    .divider{
        height: 1px;
        background-color: #ccc;
        width: 100%;
        margin-bottom: 10px;
    }
    .pricing-containers{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .basic-plan{
            margin-top: 30px;
            background-color: #F5F5F7;
            padding: 20px;
            border-radius: 16px;
            border: 1px solid #ccc;
            margin: 10px;
            width: 25%;

            .heading{
                display: flex;
                flex-direction: column;
                h2{
                    font-weight: 600;
                    margin: 0px;
                    padding: 0px;
                }
                p{
                    margin: 0px;
                    padding: 0px;
                }
            }
        
            .price{
                display: flex;
                align-items: baseline;
                align-content: baseline;
                h1{
                    text-decoration: none;
                }
                p{
                    color: grey;
                    margin-left: 5px;
                }

            }
        
            .cta{
                button{
                    width: fit-content;
                    margin-bottom: 10px;
                    width: 100%;

                }
            }
        }
        .franchise-plan{
            margin-top: 10px;
            background-color: #F5F5F7;
            padding: 20px;
            border-radius: 16px;
            border: 1px solid #ccc;
            margin: 10px;
            width: 25%;

            .heading{
                display: flex;
                flex-direction: column;
                h2{
                    font-weight: 600;
                    margin: 0px;
                    padding: 0px;
                }
                p{
                    margin: 0px;
                    padding: 0px;

                }
            }
        
            .price{
                display: flex;
                align-items: baseline;
                align-content: baseline;
                h1{
                    text-decoration: none;
                }
                p{
                    font-size: 30px;
                }

            }
        
            .cta{
                button{
                    width: fit-content;
                    margin-bottom: 10px;
                    width: 100%;

                }
            }
        }
    }

    .pricing-difference{
        display: flex;
        flex-direction: column;

        .row{
            display: flex;
            width: 100%;
            cursor: pointer;

            .feature{
                width: 25%;
            }
            .basic{
                width: 25%;
                a{
                    color: black;
                    text-decoration: none;
                }

            }
            .pro{
                width: 25%;

            }
            .enterprise{
                width: 25%;

            }

        }

        .sperator{
            height: 1px;
            background-color: #F5F5F7;
            width: 100%;
            margin: 10px 0px;
        }
    }
}