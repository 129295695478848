.members-body {
  width: 100%;

  .members-heading{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    .column{
      display: flex;
      flex-direction: column;

      h1{
        text-decoration: none;
      }
    }
  }

  button {
    margin-top: 10px;
    max-width: 200px;
  }
  .table-body{
    cursor: pointer;
    .member-photo {
      height: 30px;
      border-radius: 50%;
      width: 30px;
      object-fit: cover;
    }
  
    td{
      height: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .members-body {
    max-width: 100vw;
  }
  th,
  td {
    font-size: 55%;
    max-width: 20%;
    overflow-x: hidden;
  }
  th:first-of-type,
  td:first-of-type {
    display: none;
  }
}
