// $dark-color: #331687;
@import "../../../../Colors.scss";

.amenities-body {
  margin-top: 10px;
  height: fit-content;

  .amenities-heading{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    
    .column{
      display: flex;
      flex-direction: column;
    }
  }
  .amenities-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 4px;
    padding-bottom: 50px;
    margin-top: 20px;
    
    .amenities-row {
      width: 50%;
      min-width: 275px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin: 10px 0;

      @media screen and (max-width: 800px) {
        width: 100%;
      }

      button{
        margin: 0 auto;
      }

      .amenities-label {
        // text-transform: capitalize;
        display: flex;
      }
      .amenities-info{
        position: absolute;
        top: 0;
        border: black 2px solid;
        border-radius: 10px;
        background-color: $background;
        z-index: 5;
        padding: 10px 15px;
        margin-left: 30px;
        max-width: 300px;
        width: 300px;
        text-align: center;
        display: none;
      }
      .info-border{
        background-color: white;
        border: 0.5px solid #ccc;
        border-radius: 20px;
        width: 25px;
        min-width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        max-height: 25px;

        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          fill: #000;
          width: 20px;
          min-width: 20px;
          max-height: 20px;
        }

        .bi-info-circle{
          cursor: pointer;
          color: black;
        }
      }
    }
  }

  .amenities-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
    top: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .amenities-switch-slider {
      position: absolute;
      cursor: pointer;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 10px;
      background-color: $light-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $background;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .amenities-switch-slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

    input:focus + .amenities-switch-slider {
      box-shadow: 0 0 1px $dark-color;
    }

    input:checked + .amenities-switch-slider {
      background-color: $dark-color;
    }
  }
}
