@import '../../../../Colors.scss';

.hours-body {
  margin-top: 10px;

  .hours-heading {
    background-color: white;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
    }
  }

  .hours-special {
    border-radius: 4px;

    .hours-row {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h5{
        margin-left: 25px;
      }

      @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: row;
      }

      hr {
        border-top: 2px solid black;
      }



      h4 {
        width: 15%;
        text-decoration: underline;

        &:first-child {
          width: 10%;
          padding-left: 3%;
        }
      }
    }

    .hours-switch {
      position: relative;
      display: inline-block;
      width: 65px;
      height: 34px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .hours-switch-slider {
        position: absolute;
        cursor: pointer;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: $light-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: '';
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: $background;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked+.hours-switch-slider:before {
        -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
        transform: translateX(30px);
      }

      input:focus+.hours-switch-slider {
        box-shadow: 0 0 1px $dark-color;
      }

      input:checked+.hours-switch-slider {
        background-color: $dark-color;
      }
    }
  }

  .hours-normal {
    border-radius: 4px;
    padding-bottom: 20px;

  }

  h1 {
    margin-bottom: 10px;
  }

  .hours-row {
    display: flex;
    align-items: center;

    .row {
      display: flex;
      margin-left: 5px;
    }

    .group{
      display: flex;
      .open-time {
        margin-right: 5px;
      }

      .copy-times{
        cursor: pointer;
        .custom-dropdown-button {
          border-radius: 4px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          :after {
            display: none !important;
          }
        }
        
      }
    }


    hr {
      border-top: 2px solid black;
    }

    h5 {
      width: 125px;
      font-size: 15px;
      height: 30px;
      margin-left: 15px;

      @media screen and (max-width: 800px) {
        margin-left: 5px;
      }
    }

    h4 {
      width: 15%;

      &:first-child {
        width: 10%;
        padding-left: 3%;
      }
    }
  }

  .hours-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .hours-switch-slider {
      position: absolute;
      cursor: pointer;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 10px;
      background-color: $light-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $background;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked+.hours-switch-slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

    input:focus+.hours-switch-slider {
      box-shadow: 0 0 1px $dark-color;
    }

    input:checked+.hours-switch-slider {
      background-color: $dark-color;
    }
  }
}

@media screen and (max-width: 800px) {



  .hours-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    .row {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    .group{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }
  }
}