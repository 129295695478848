@import '../../../Colors.scss';

.info-nav-link{
  a{
    color: $dark-color;
    background-color: $background;

    &:hover {
      color: $dark-color;
    }
  }
  .active{
    color: $background !important;
    background-color: $dark-color !important;
  }
}