.premium-container {
    margin-top: 10px;
    background-color: #F5F5F7;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #ccc;
    margin: 10px;
    width: 25%;

    .premium-heading {
        display: flex;
        flex-direction: column;


        h2 {
            font-weight: 600;
            margin: 0px;
            padding: 0px;
        }

        p {
            margin: 0px;
            padding: 0px;

        }
    }

    .premium-price {
        display: flex;
        align-items: baseline;
        align-content: baseline;

        h1 {
            text-decoration: none;
        }

        p {
            color: grey;
            margin-left: 5px;

        }
    }

    .premium-cta {
        button {
            width: fit-content;
            width: 100%;
        }
    }

    .update-subscription{
        font-size: 10px;
        color: grey;

        .custom-button-hyperlink{
            font-size: 10px;
            color: grey; 
        }
    }
}

// .modal-content{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 20px;
//     background-color: white;
//     max-width: 400px;
//     margin: 0 auto;

//     h2{
//         font-weight: 600;
//         margin-bottom: 20px;
//     }
//     button{
//         width: fit-content;
//         margin-top: 20px;
//     }
// }