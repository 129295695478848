@import "../../../../Colors.scss";

.class-data-body {
  padding: 10px 20px;

  .class-button {
    margin-top: 10px;
    background-color: $dark-color !important;
    border: $dark-color;

    &:hover {
      background-color: $dark-color !important;
    }
  }

  .delete {
    background-color: red !important;
    &:hover {
      background-color: darkred !important;
    }
  }

  .alert-max{
    color: red;
  }
  

  .class-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .bi-arrow-repeat {
      font-size: 30px;
      cursor: pointer;

      &:hover:after {
        content: 'Recurring Class';
        font-size: 15px;
        position: absolute;
        text-shadow: $background;
        right: 55px;
        top: 10px;
      }
    }
  }


  .add-class-input{

    margin-top: 10px;
  }
  .class-section {
    .members-section, .attendee-section{
      min-height: 100px;
      max-height: 200px;
      border: 1px solid #ccc;
      border-radius: 16px;
      margin-bottom: 10px;
      overflow-x: auto;
    }
    .attendee-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      min-height: 40px;
      padding: 5px 10px;
      

      .bi-plus-square{
        height: 25px;
        color: $dark-color;
        cursor: pointer;

        &:hover:after {
          content: 'Add to Session';
          position: absolute;
          right: 55px;
        }
      }
      .bi-x-square {
        height: 25px;
        color: red;
        cursor: pointer;

        &:hover:after {
          content: 'Remove';
          position: absolute;
          right: 55px;
        }
      }
    }
    .not-added{
      background-color: white;
      cursor: pointer;
    }
  }
  .class-footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}