@import "../../Colors.scss";

@keyframes navCollapse {
  from {
    left: 0;
  }

  to {
    left: -180px;
  }
}

@keyframes navOpen {
  from {
    left: -180px;
  }

  to {
    left: 0;
  }
}

@keyframes navOpenIcon {
  from {
    right: 280px;
  }

  to {
    right: -25px;
  }
}

@keyframes infoExpand {
  from {
    height: 0;
    margin-bottom: 0px;
    display: none;
  }

  to {
    height: 50px;
    margin-bottom: 10px;
    display: block;
  }
}

@keyframes iconSlide {
  from {
    padding-left: 60px;
  }

  to {
    padding-left: 200px;
  }
}

@keyframes logoSlide {
  from {
    padding-left: 60px;
    margin-right: 80px;
  }

  to {
    padding-left: 200px;
    margin-right: 20px;
  }
}

@keyframes navHoverSlide {
  from {
    left: -75px;
  }

  to {
    left: 30px;
  }
}

.navigation-open {
  animation: navOpen 0.3s linear 0s forwards;
}

.navigation-close {
  animation: navCollapse 0.3s linear 0s forwards;

  label {
    display: none;
  }

  .navigation-link {
    padding-left: 200px;
    animation: iconSlide 0.3s linear 0s forwards;
  }

  .navigation-head {
    transform: translateX(-100px);
  }

  .sublink {
    justify-content: left;
    margin-left: 0;

    &::before {
      content: '';
    }
  }

  .navigation-collapse-icon {
    margin-right: 20px;
    color: gray;
  }
}


.navigation-container {
  height: 100vh;
  width: 250px;
  background-color: white;
  position: relative;
  z-index: 5;
  border-right: 0.5px solid #ccc;

  a {
    color: black;

    &:hover {
      color: black;
    }
  }

  .copy-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    width: 150px;
    text-align: center;
    background-color: #ffffff;
    height: 35px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;

  }
}

.navigation-collapse-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  transition: color .3s ease-in-out;  
  &:hover{
    color: gray;
  }
}

.navigation-open-icon {
  position: absolute;
  right: -25px;
  top: 5px;
  font-size: 20px;
  cursor: pointer;
  animation: navOpenIcon 0.3s linear 0s forwards;

  &::after {
    content: 'Menu';
    position: absolute;
  }
}

.navigation-head {
  height: 350px;
  max-height: 100px;
  display: flex;
  margin-left: 15px;
  align-items: center;
  align-content: center;

  h3 {
    text-align: left;
    font-size: 15px;
    margin-left: 10px;
  }

  .navigation-user-logo-container {
    height: 45px;
    width: 45px;
    border-radius: 8px;
    background-color: $background;

    .gym-logo {
      height: 45px;
      width: 45px;
      border-radius: 8px;
      object-fit: cover;

    }
  }
}

.navigation-navigation-container {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: $dark-color;
  }

  .navigation-link {
    height: 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    padding-left: 15px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;


    & label {
      cursor: pointer;
    }

    &:hover {
      background-color: #ebebed;
    }

    .bi {
      margin-right: 10px;
    }
  }

  .sublink {
    animation: infoExpand 0.1s linear 0s forwards;
    margin-left: 20px;
    justify-content: left;
    padding-left: 40px;
    // &::before {
    //   content: '-';
    //   margin-right: 10px;
    // }
  }

  .selected-link {
    background-color: #ebebed;
  }

  .navigation-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 600px);
    max-height: 15%;
  }

  .footer-links {
    height: 200px;
    width: 250px;

    p {
      color: gray;
      font-size: 10px;
      margin-top: 5px;
      margin-left: 25px;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}

.navigation-magfit-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navigation-magfit-logo {
  // position: absolute;
  height: 55px;
}

.navigation-magfit-icon {
  // position: absolute;
  animation: logoSlide 0.3s linear 0s forwards;
  height: 60px;
  margin-right: 60px;
}

%iconHoverTag {
  animation: navHoverSlide 0.1s linear 0s forwards;
  position: absolute;
  z-index: -1;
  top: -6px;
  left: -76px;
  height: 50px;
  color: $background;
  background-color: $dark-color;
  margin-left: 10px;
  padding: 5px 15px 5px 15px;
  border-radius: 10px;
  white-space: nowrap;
}

// .scheduleHover:hover {
//   position: relative;
//   width: 50px;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Schedule';
//   }
// }

// .membersHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Trainers';
//   }
// }

// .communityHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Community';
//   }
// }

// .homeHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Home';
//   }
// }

// .gymInfoHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Gym Info';
//   }
// }

// .hoursHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Hours';
//   }
// }

// .amenitiesHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Amenities';
//   }
// }

// .mediaHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Media';
//   }
// }

// .communityHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Community';
//   }
// }

// .servicesHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Services';
//   }
// }

// .settingsHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Account';
//   }
// }

// .logOutHover:hover {
//   position: relative;

//   &::after {
//     @extend %iconHoverTag;
//     content: 'Log Out';
//   }
// }

@media screen and (max-width: 700px) {
  .navigation-container {
    height: 70px;
    width: 100vw;
    background-color: $background;
    position: absolute;
    border-top: 1px solid #ccc;
    bottom: 0;

    .bi-chevron-double-left {
      display: none;
    }

    .navigation-head {
      display: none;
    }

    .navigation-navigation-container {
      flex-direction: row;
      justify-content: space-evenly;

      label {
        display: none;
      }

      .main-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
        justify-content: space-between;

        .navigation-link {
          background-color: $background;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          i {
            font-size: 20px;
            margin: 0 auto;
          }

          &:hover {
            color: $dark-color;
          }
        }

        .selected-link {
          color: $dark-color;
        }
      }
    }

    .navigation-footer {
      display: none;
    }
  }
}