@import "../../../../Colors.scss";

.edit-gym-info-body {
  max-width: 100%;
  margin: 10px auto;

  .info-heading{
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: space-between;

    .group{
      display: flex;
      flex-direction: column;
    }
  }

  .info-body {
    width: 100%;
  }

  .info-user-photo-container {
    height: 120px;
    width: 120px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 25px;
    

    .logo-image {
      height: 120px;
      width: 120px;
      border-radius: 8px;
      cursor: pointer;
      object-fit: cover;

    }
    
    #logo-image {
      position: absolute; /* Position the input absolutely */
      top: 0;
      left: 0;
      height: 120px;
      width: 120px;
      opacity: 0;
    }

    .logo-placeholder{
      width: 120px;
      background-color: white;
      height: 120px;
      border-radius: 8px;
      border: 1px dashed #ccc;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: grey;
      font-size: 12px;


      &:hover{
        border: 1px dashed grey;

      }
    }
  }
  
  .info-gym-info {
    width: 100%;

    p {
      margin: 0 0 10px 20px;
    }
  }

}
.pac-container::after,
.pac-item::after {
  content: none !important;
}