@import '../../../Colors.scss';


.signup-1-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  
  .step-counter{
    margin-top: 5vh;
    width: 40vw;

    @media screen and (max-width: 800px) {
      width: 100vw;
      padding-left: 20px;
    }
    h1{
      text-decoration: none;
    }


    p{
      color: gray;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .step{

      p{
        color: black;
      }

      .step-indicator{
        display: flex;
        margin-bottom: 15px;
        .step1{
          width: 50px;
          height: 5px;
          background-color: black;
          margin-right: 10px;
          border-radius: 4px;
        }
        .step2{
          width: 50px;
          height: 5px;
          background-color: lightgrey;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }


  .signup-1-main{
    width: 40vw;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F5F5F7;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-bottom: 30px;
    padding-top: 30px;
    @media screen and (max-width: 800px) {
      width: 100vw;
      padding-left: 20px;
      padding-right: 20px;
      background-color: white;
      border: none;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .description{
        h4{
          max-width: 500px;
          min-width: 120px;
        }
        p{
          max-width: 500px;
          min-width: 120px;
          color: rgb(24, 24, 24);
          font-size: 14px;
  
        }
        .account{
          margin-top: 15px;
        }
      }
      hr{
        border-top: 2px solid black;
        width: 100%;
      }
      input, .mb-3{
        max-width: 500px;
        min-width: 120px;
        background-color: white;
        border-radius: 8px;
  
        .currency-style{
          background-color: white;
          color: black;
          cursor: pointer;
        }
      }
      select{
        width: 100%;
      }
      .password-check{
        margin: 0;
        padding: 0;

        p{
          margin: 0;
          padding: 0;
        }
        i{
          margin-right: 2px;
        }
      }
      .signup-buttons{
        display: flex;
        justify-content: center;
        .custom-button {
          background-color: $dark-color;
          border:  $dark-color;
          box-shadow: $dark-color 0 6px 20px -10px;
          cursor: pointer;
          width: 100%;
          &:hover{
            background-color: $med-color !important;
          }
      
          &:active{
            transform: scale(.98)
          }
        }
      }
      .terms-signup{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: gray;
        font-size: 12px;
  
        a{
          color: gray;
          margin-left: 3px;
        }
      }
      span{
        display: flex;
        justify-content: center;
        a{
          color: black;
          margin-left: 5px;
        }
      }
    }
  }
}
