.chart-body{

    h3{
        margin-top: 22px;
    }
    p{
        color: grey;
    }
    .bar-chart{
        background-color: #F5F5F7;
        padding: 20px;
        border-radius: 16px;
        width: 100%;
    }
}