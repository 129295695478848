@import "../../../../Colors.scss";

.member-button {
  background-color: $dark-color !important;
  border: $dark-color !important;

  &:hover {
    background-color: $dark-color !important;
  }
}
.add-member-modal {

  .add-member-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .add-member-input {
      width: 300px;
      max-width: 80vw;
    }
    input {
      margin-bottom: 10px;
    }
    .custom-button{
      width: 300px;
      color: white;
    }
  }
}
