// Navigation Prompt Styles

.navigation-prompt-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .navigation-prompt {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }

    .button-group {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
      button {
        margin-top: 20px;
      }
    }
  }
}
