.change-password-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;

  @media only screen and (max-width: 700px) {
    height: fit-content;
  }

  .change-password-container{
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    background-color: #F5F5F7;
    border: 1px solid #ccc;
    border-radius: 16px;
    width: 500px;
    height: 500px;
    margin: 30px;
    padding: 20px;

    @media only screen and (max-width: 700px) {
      width: 350px;
      height: 500px;
    }

    .icon-gym-password{
      background-color: black;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-content: center;
      height: 400px;
      font-size: 175px;
      border-radius: 16px;

    }
    h3{
      max-width: 500px;
      padding-left: 10px;
      margin-top: 15px;
    }
    .info{
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }

}