@import "../../../../Colors.scss";

.add-class-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;

  .add-class-input {
    margin-top: 10px;
    width: 300px;
  }
  .dropdown{
    width: 300px;
  }
  button {
    margin-top: 10px;
    background-color: $dark-color;
    border: $dark-color;

    &:hover {
      background-color: $med-color !important;
    }
  }
  .recurring-label {
    margin-top: 10px;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
    top: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .switch-slider {
      position: absolute;
      cursor: pointer;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 10px;
      background-color: $light-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $background;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .switch-slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

    input:focus + .switch-slider {
      box-shadow: 0 0 1px $med-color;
    }

    input:checked + .switch-slider {
      background-color: $dark-color;
    }
  }
}
