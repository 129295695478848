.claim-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: #F5F5F7;

    #claim-logo{
      position: absolute;
      top: 20px;
      left: 20px;
      width: 200px;
      height: auto;
    }

    .claim-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        padding: 30px;
        border-radius: 16px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: white;

  
        span,
        input {
          background-color: white;
        }
  
        .login_err {
          background-color: red;
          color: red;
          font-weight: 700;
        }
  
        button {
          width: 100%;
        }
  
        a {
          color: black;
        }

  
        .sign-up-link {
          margin-top: 25px;
        }

        #claim-logo{
            width: 150px;
            height: 100%;
        }
  
        #passwordHelp {
          width: 100%;
          text-align: right;
  
          a{
            color: grey;
            text-decoration: none;
          }
        }
        
        #password-show-box{
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          cursor: pointer;
        }
  
      }
}