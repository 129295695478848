@import '../../../../Colors.scss';

.services-body{
margin-top: 10px;

  .column-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 4px;
    margin-top: 20px;

    .day-pass-links{
      width: 100%;
    }
    .service-column{
      width: 100%;
      // min-width: 275px;
      // max-width: 400px;

      .service-row{
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        margin: 10px 0;
        margin-bottom: 20px;


        button{
          margin: 0 auto;
        }

        .info-span{
          display: flex;

          .info-border{
            background-color: white;
            border: 0.5px solid #ccc;
            border-radius: 20px;
            width: 25px;
            min-width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            max-height: 25px;

            svg {
              display: flex;
              justify-content: center;
              align-items: center;
              fill: #000;
              width: 20px;
              min-width: 20px;
              max-height: 20px;
            }
    
            .bi-info-circle{
              cursor: pointer;
              color: black;
            }
          }
        }
      }
    }
  }
  .service-heading{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    
    .column{
      display: flex;
      flex-direction: column;
    }
  }
  .drop-in-section{
    .currency-input{
      width: 50%;
      min-width: 300px;
      max-width: 350px;

    }

  }


  .amenity-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
    top: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .amenity-switch-slider {
      position: absolute;
      cursor: pointer;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 10px;
      background-color: $light-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $background;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .amenity-switch-slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

    input:focus + .amenity-switch-slider {
      box-shadow: 0 0 1px $dark-color;
    }

    input:checked + .amenity-switch-slider {
      background-color: $dark-color;
    }
  }
}