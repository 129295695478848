@import "../../../Colors.scss";

.login-body {
  width: 100vw;
  height: 100vh;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .login-group {
    display: flex;
    flex-direction: row;
    width: 100vw;

    @media only screen and (max-width: 1000px) {
      justify-content: center;
    }

    .login-cta {
      background-color: black;
      width: 50%;
      border-radius: 16px;


      .carousel {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
        color: #fff;
        background-color: #131313;

        .carousel-text {
          position: absolute;
          top: 90px;
          left: 50px;
          z-index: 2;
          color: #fff;
          padding: 10px;
        }
        


        img {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
  
        }
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }


    .login-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .login-header {
        margin-bottom: 40px;
        #login-magfit-logo {
          height: 2rem;
          margin-bottom: 10px;
        }

      }

      span,
      input {
        background-color: white;
      }

      .login_err {
        background-color: $background;
        color: red;
        font-weight: 700;
      }

      button {
        width: 100%;
      }

      a {
        color: $dark-color;
      }

      hr {
        width: 80%;
        border-top: 2px black solid;
      }

      .sign-up-link {
        margin-top: 25px;
      }

      #passwordHelp {
        width: 100%;
        text-align: right;

        a{
          color: grey;
          text-decoration: none;
        }
      }
      
      #password-show-box{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        cursor: pointer;
      }

      @media only screen and (max-width: 1000px) {
        width: 90%;
      }
    }
  }

}