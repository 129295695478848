.numbers-body{


    .numbers-group{
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    
        .number-container{
            background-color: #F5F5F7;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25%;
            height: 120px;
            border-radius: 16px;
            margin-left: 5px;
            margin-right: 5px;
            .description{
                display: flex;
                color: grey;

                p{
                    margin-right: 5px;
                }
                i{
                    cursor: pointer;
                }
            }
            @media screen and (max-width: 1000px) {
                margin-left: 0px;
                margin-right: 0px;
                width: 100%;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .number-container:first-child {
            margin-left: 0;
            @media screen and (max-width: 1000px) {
                margin-top: 10px;
            }
        }
        
        .number-container:last-child {
            margin-right: 0;
        }
        


        .number-bottom-container{
            background-color: #F5F5F7;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 33%;
            height: 120px;
            border-radius: 16px;
            margin-left: 5px;
            margin-right: 5px;
            .description{
                display: flex;
                color: grey;

                p{
                    margin-right: 5px;
                }
                i{
                    cursor: pointer;
                }
            }
            @media screen and (max-width: 1000px) {
                margin-left: 0px;
                margin-right: 0px;
                width: 100%;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .number-bottom-container:first-child {
            margin-left: 0;
            @media screen and (max-width: 1000px) {
                margin-top: 10px;
            }
        }
        
        .number-bottom-container:last-child {
            margin-right: 0;
        }
    }

    .class-button{
        margin-top: 10px;
        a{
            color: white;
            text-decoration: none;
        }
    }
}

.payment-home-body{
    .transaction-heading{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        .group{
            display: flex;
            flex-direction: column;

            p{
                color: grey;
            }
        }
    }
    .payments {

        .spinner-container{
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 300px;
        }
        .payment-session-card {
          display: flex;
          width: 100%;
          border-bottom: 1px dashed #e0e0e0;
          padding: 10px;
    
          p {
            margin: 0;
          }
      
          .left-section {
            width: 50%;
          }
          .right-section {
            width: 50%;
            display: flex;
            flex-direction: column;

            .refunded {
                color: red;
            }
            
            .paid {
                border: 1px solid #c8e6c9;
                border-radius: 8px;
                background-color: #f1f8e9;
                color: #4caf50;
                width: 100px;
                text-align: center;
            }
            
            .unpaid {
                color: orange;
            }
          }
        }
      }
}