.comment-form{
  width: 70%;
  min-width: 300px;
  height: 50vh;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.logout-button{
  border: none;
  background-color: white;
  color: gray;
}