.comment-form{
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: #F5F5F7;
  border: 1px solid #ccc;
  border-radius: 16px;
  width: 500px;
  height: 500px;
  padding: 10px;
  margin-top: 90px;
  box-sizing: border-box;

  @media only screen and (max-width: 700px) {
    height: fit-content;
    width: 350px;
    height: 500px;
  }

  button{
    width: 100%;

  }
}