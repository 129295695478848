@import "../../Colors.scss";

@keyframes pageExpand {
  from {
    width: calc(96vw - 250px);
    left: 250px;
    margin: 5vh 2vw 2vw;
  }
  to {
    width: calc(96vw - 70px);
    left: 70px;
    margin: 5vh 2vw 2vw;
  }
}

@keyframes pageCollapse {
  from {
    width: calc(96vw - 70px);
    left: 70px;
    margin: 5vh 2vw 2vw;
  }
  to {
    width: calc(96vw - 250px);
    left: 250px;
    margin: 5vh 2vw 2vw;
  }
}

.page-expand {
  animation: pageExpand 0.3s linear 0s forwards;
}
.page-collapse {
  animation: pageCollapse 0.3s linear 0s forwards;
}

.page-template {
  width: calc(96vw - 250px);
  height: 80vh;
  min-height: 90vh;
  background-color: $background;
  position: absolute;
  margin: 5vh 2vw 2vw;
  padding: 4vh 3vw;
  border-radius: 10px;
  left: 250px;
  overflow-y: auto;
}
.page-expanded {
  width: calc(96vw - 70px);
  left: 70px;
  margin: 5vh 2vw 2vw;
}
.gym-select-dropdown{
  position: absolute;
  right: 2vw;
  top: 2vh;
  background-color: $background;
  border-radius: 10px 10px 0 0;
  height: 50px;
  width: fit-content;
  min-width: 200px;
  padding: 5px 10px;
  z-index: 1;

  a{
    text-align: center;
    font-weight: 500;
  }
}

@media screen and (max-width: 700px) {
  .page-template {
    width: 100vw;
    height: calc(100vh - 100px);
    min-height: 90vh;
    background-color: $background;
    position: relative;
    margin:0;
    padding: 3vw;
    border-radius: 0;
    left: 0;
    top: 50px;
    overflow-y: auto;
  }
}