
.community-body{
    width: 100%;
    height: 100%;

    h1{
        text-decoration: none;
    }

    .review-stats{
        display: flex;
        width: 100%;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
          }
        .stat-1{
            width: 25%;
            margin-right: 10px;
            border: 0.5px solid #ccc;
            padding: 20px;
            border-radius: 16px;
            &:first-child {
                margin-left: 0px;
                margin-right: 10px;

            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                margin-right: 0px;

                &:first-child {
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 10px;

                }

              }

            .star-rating{
                h3{
                    margin-right: 10px;
                    @media screen and (max-width: 1000px) {
                        margin-right: 5px;

        
                      }
                }
                display: flex;
                align-items: baseline;
            }

        }
        .stat{
            border: 0.5px solid #ccc;
            padding: 20px;
            border-radius: 16px;
            width: 50%;
            margin-left: 5px;
            &:last-child {
                margin-right: 0px;
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
                margin-left: 0px;
                margin-top: 10px;

              }

        }
    }
    .divider{
        width: 100%;
        height: 0.5px;
        background-color: #ccc;
        border-radius: 16px;

    }
    .reviews{
        .spinner-container{
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 250px;
        }
        .review{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            border: 0.5px solid #ccc;
            padding: 20px;
            border-radius: 16px;

            .review-group{
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                .username{
                    color: black;
                    font-size: 18px;
                    margin: 0px;
                    padding-bottom: 2px;

                }
                p{
                    color:  gray;
                    margin: 0px;
                    padding-top: 2px;
                    font-size: 14px;

                }
            }
            .divider{
                width: 100%;
                height: 0.5px;
                background-color: #ccc;
                border-radius: 16px;
        
            }
        }
    }
}
.reviews-progress{
    display: flex;

    .label{
        margin-right: 10px;
        width: 120px;

    }
    .rating{
        margin-left: 10px;

    }
}