@import "../../../../Colors.scss";

.equipment-body {
  margin-top: 10px;
  max-width: 100%;

  @media only screen and (max-width: 800px) {
    margin-bottom: 75px;


  }
  .equipment-heading{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    
    .column{
      display: flex;
      flex-direction: column;
    }
  }

  .equipment-section {
    display: flex;
    height: fit-content;
    max-width: 100%;
    flex-wrap: wrap;
    border-radius: 4px;
    margin-top: 15px;

    .equipment-item {
      width: 50%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 10px;

      @media only screen and (max-width: 800px) {
        width: 100%;

      }

      .equipment-label {
        display: flex;
        padding-top: 5px;
        .info-border{
          background-color: white;
          border: 0.5px solid #ccc;
          border-radius: 20px;
          width: 25px;
          min-width: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          max-height: 25px;
  
          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            fill: #000;
            width: 20px;
            min-width: 20px;
            max-height: 20px;
          }
  
        }
      }
    }

    .equipment-switch {
      position: relative;
      display: inline-block;
      min-width: 65px;
      height: 34px;
      top: 10px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .equipment-switch-slider {
        position: absolute;
        cursor: pointer;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: $light-color;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: $background;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .equipment-switch-slider:before {
        -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
        transform: translateX(30px);
      }

      input:focus + .equipment-switch-slider {
        box-shadow: 0 0 1px $dark-color;
      }

      input:checked + .equipment-switch-slider {
        background-color: $dark-color;
      }
    }

    .equipment-save{
      margin: 0 auto;
    }
  }
}
