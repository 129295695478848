// Store Styles

.cart-button-container {
  position: fixed;
  top: 20px;
  right: 20px;

  .cart-icon {
    margin-right: 5px;
  }
}

.cart-items {
  .cart-item {
    display: flex;

    .section {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      button {
        margin-left: auto;
      }
    }
  }
}
.store-title {

  h1 {
    text-decoration: none;
  }
}
.store-body {

  .store-controls {
    margin-bottom: 20px;
    button {
      margin-right: 10px;
    }
  }

  .store-items {
    .spinner-container{
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;
      height: 250px;
  }
    .store-item {
      display: flex;
      border-bottom: 1px dashed #e0e0e0;
      padding: 20px 0;

      &:last-child {
        border-bottom: none;
      }
      .left-side {
        display: flex;

        .group{
          display: flex;
          flex-direction: column;
        }
        p {
          max-width: 400px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        img {
          width: 70px;
          height: 70px;
          border-radius: 4px;
          object-fit: cover;
        }
      }
      .right-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        button {
          margin-top: 10px;
        }
      }
    }
  }
}
.payment-details {
  display: flex;
  flex-direction: column;
  
  p {
    margin: 0;
  }
}
.refund-confirmation{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.payment-history {
  .payment-session-card {
    display: flex;
    width: 100%;
    border-bottom: 1px dashed #e0e0e0;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #f0f0f0;
    }

    p {
      margin: 0;
    }

    .left-section {
      width: 50%;
    }
    .right-section {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
}
.ant-pagination-item-active {
  border: 1px solid black !important;
}
.new-product{
  width: 100%;
}
.edit-item-buttons{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.view-reciept{
  a{
    text-decoration: none;
  }
  i{
    margin-left: 5px;
  }
}
.stripe-dashboard-link{
  text-decoration: underline;
  cursor: pointer;
  color: #6772e5;
}