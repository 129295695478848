@import url("https://fonts.cdnfonts.com/css/switzer");
@import "./Colors.scss";

.App{
    height: 100vh;
    --toastify-color-progress-dark: white;
}
.firebase-emulator-warning {
  display: none;
}
.custom-button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid $dark-color;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 8px;
  background-color: $dark-color;
  // box-shadow: $dark-color 0 6px 20px -10px;
  color: white;
  &:hover {
    background-color: $med-color;
  }

  &:active {
    transform: scale(0.98);
  }
  &:disabled {
    background-color: #e0e0e0;
    border: transparent;
    color: #000;
    box-shadow: none;
    cursor: not-allowed;
  }
  a {
    color: white;
    text-decoration: none;
  }

  &-second {
    @extend .custom-button;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    box-shadow: $dark-color 0 6px 20px -100px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    &:hover {
      background-color: #e4e3e3;
      color: black;
      border: 1px solid #ccc;
    }

    a{
      color: black;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &-third {
    @extend .custom-button;
    background-color: white;
    border: 1px dashed #ff0000;
    box-shadow: #ff0000 0 6px 20px -100px;
    cursor: pointer;
    color: red;
    &:hover {
      background-color: white;
      color: red;
      border: 1px dashed #ff0000c4;
    }

    &:active {
      transform: scale(0.98);
    }
  }
  &-fourth {
    @extend .custom-button;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: $dark-color 0 6px 20px -100px;
    cursor: pointer;
    color: black;
    &:hover {
      background-color: #fdfdfd;
      color: black;
      border: 1px solid #ccc;
    }

    a{
      color: black;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &-hyperlink {
    @extend .custom-button;
    background: none !important;
    border: none;
    padding: 0 !important;
    margin-bottom: 2px !important;
    box-shadow: none;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-size: 16px;
    &:hover {
      background-color: transparent;
      color: $med-color;
      border: none;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}
.alternate-custom-button {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 8px;
  background-color: white;
  // box-shadow: $dark-color 0 6px 20px -10px;
  color: black;
  &:hover {
    background-color: white;
  }

  &:active {
    transform: scale(0.98);
  }
  &:disabled {
    background-color: #e0e0e0;
    border: transparent;
    color: #000;
    box-shadow: none;
    cursor: not-allowed;
  }
  a {
    color: black;
    text-decoration: none;
  }

  &-second {
    @extend .custom-button;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    box-shadow: $dark-color 0 6px 20px -100px;
    cursor: pointer;
    color: black;
    &:hover {
      background-color: #e4e3e3;
      color: black;
      border: 1px solid #ccc;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &-third {
    @extend .custom-button;
    background-color: #ff0000;
    border: 1px solid #ff0000;
    box-shadow: #ff0000 0 6px 20px -100px;
    cursor: pointer;
    color: white;
    &:hover {
      background-color: #ff0000;
      color: white;
      border: 1px solid #ff0000;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &-hyperlink {
    @extend .custom-button;
    background: none !important;
    border: none;
    padding: 0 !important;
    margin-bottom: 2px !important;
    box-shadow: none;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    font-size: 16px;
    &:hover {
      background-color: transparent;
      color: $med-color;
      border: none;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}
.stripe-button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid $stripe-dark-color;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 8px;
  background-color: $stripe-dark-color;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;

  .bi-stripe {
    margin-left: 5px;
  }

  &:hover {
    background-color: $stripe-med-color;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: #e0e0e0;
    border: transparent;
    color: #000;
    box-shadow: none;
    cursor: not-allowed;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &-second {
    @extend .custom-button;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    box-shadow: $stripe-dark-color 0 6px 20px -100px;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: #e4e3e3;
      color: black;
      border: 1px solid #ccc;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

.App {
  background-color: $background;
  display: flex;
  font-family: "Switzer", sans-serif !important;

  h1 {
    text-decoration: underline;
  }

  .custom-switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
    top: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .custom-switch-slider {
      position: absolute;
      cursor: pointer;
      top: -10px;
      left: 0;
      right: 0;
      bottom: 10px;
      background-color: $light-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $background;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .custom-switch-slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }

    input:focus + .custom-switch-slider {
      box-shadow: 0 0 1px $dark-color;
    }

    input:checked + .custom-switch-slider {
      background-color: $dark-color;
    }
  }
}
