.photos-body {
    margin-top: 10px;
    max-width: 100%;

    .media-heading {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;

        .column {
            display: flex;
            flex-direction: column;
            max-width: 60%;
        }

        .photo-buttons {
            display: flex;
            flex-direction: row;

            a {
                text-decoration: none;
                color: white;
            }
        }
    }


    .photos {
        display: flex;
        flex-wrap: wrap;
        margin-top: 45px;

        .photo {
            border: 1px dashed #ccc;
            background-color: rgb(242, 239, 239, 0.4);
            height: 250px;
            width: 250px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 10px;
            .remove-button {
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgba(255, 255, 255);
                color: black;
                border-radius: 50%;
                margin: 10px;
                cursor: pointer;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-size: 10px;
                border: 1px solid #ccc;
                

            }

            img {
                height: 95%;
                width: 95%;
                border-radius: 16px;
                object-fit: cover;
            }
        }
        .spinner-container{
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 300px;
        }
    }

}