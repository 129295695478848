// Payment Settings Styles

// .stripe-section {
// }

.payment-settings-body {
  max-width: 100%;
  margin: 10px auto;
  .subscription-section {
    .subscription-packages {
      .subscription-heading {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;

        button{
          margin-left: 20px;
        }
      }
      .subscription-package {
        border-bottom: 1px solid #e0e0e0;
        padding: 20px 0;
        .package-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          width: 100%;

          .enable-span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            label {
              margin-left: 5px;
            }
          }
          .activate {
            color: gray;
          }
          .activated {
            color: lightgray;
          }
        }
        .package-body {
          .price-input {
            font-size: 25px;
            border-radius: 4px;
            background-color: rgb(242, 239, 239, 0.4);
            border: none;
            padding: 5px;
          }
        }
      }
    }
  }
}
