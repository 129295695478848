.help-body {
  .need-help {
    background-color: #F5F5F7;
    margin-top: 20px;
    padding: 20px;
    border-radius: 16px;

    .help-buttons {
      display: flex;

      .help-button {
        margin-left: 5px;
        text-align: center;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        border: 0.5px solid #ccc;
        width: 35px;
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-size: 14px;
        border-radius: 50%;
        background-color: white;
        color: black;

        &:hover {
          background-color: white;
        }

        &:active {
          transform: scale(0.98);
        }

        &:disabled {
          background-color: #e0e0e0;
          border: transparent;
          color: #000;
          box-shadow: none;
          cursor: not-allowed;
        }

        a {
          color: black;
          text-decoration: none;
        }

      }

      .tour-button {
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
    }



  }
}