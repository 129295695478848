.edit-member-body {
  padding: 20px;

  h4{
    text-decoration: none;
    font-size: 16px;
  }

  .divider{
    height: 1px;
    background-color: #ccc;
    width: 100%;
  }

  span{
    font-weight: 300;
    font-size: 16px;

  }

  .edit-member-input {
    margin-bottom: 10px;
  }
  
  .member-modal-photo {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

}
.delete-buttons {
  display: flex;
  justify-content: space-between;
  .member-delete-button {
    background-color: red !important;
    border-color: red !important;
  }
}
