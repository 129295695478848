.to-do-body{


    .to-do-heading{
        background-color: #F5F5F7;
        padding: 20px 20px;
        border-radius: 16px;

        .to-do-progress{

            .progress-wrapper {
                background-color: white;
                height: 15px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                border: 1px #ccc solid;
    
                .red-progress-bar {
                    background: linear-gradient(to right, #141413, black);
                    height: 100%;
                    border-radius: 50px;
                }
              }

              p{
                text-align: right;
                color: grey;
              }
        }

    }
    .to-do-container{
        background-color: #F5F5F7;
        margin-top: 20px;
        padding: 20px;
        border-radius: 16px;


        .to-do-row{
            display: flex;
            flex-direction: row;
            align-items:center;

            a{
                color: black;
                text-decoration: none;
            }

            .to-do-content{
                display: flex;
                flex-direction: row;
                padding: 8px;

                h4{
                    font-weight: 300;
                    font-size: 15px;
                    text-decoration: none;

                }
                .circle-empty{
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 4px;
                    background-color: rgb(217, 217, 217);
                    margin-right: 20px;

                    &:hover{
                        background-color: white;
                        border: 1px #ccc solid;
                    }

                }
                .circle-filled{
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 4px;
                    background-color: white;
                    margin-right: 20px;
                    color: #141413;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px #ccc solid;

                    i{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;

                    }

                }

                &:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .to-do-finished{
                display: flex;
                flex-direction: row;
                padding: 8px;

                h4{
                    font-weight: 300;
                    font-size: 15px;
                    text-decoration:line-through;

                }
                .circle-empty{
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 4px;
                    background-color: rgb(217, 217, 217);
                    margin-right: 20px;

                    &:hover{
                        background-color: white;
                        border: 1px #ccc solid;
                    }

                }
                .circle-filled{
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 4px;
                    background-color: white;
                    margin-right: 20px;
                    color: #141413;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px #ccc solid;

                    i{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;

                    }

                }

                &:hover{
                    cursor: pointer;
                }
            }
            
        }

        .all-completed{

            a{
                color: white;
                text-decoration: none;
            }

            img{
                height: 100%;
                width: 150px;
            }
        }
    }
    .need-help{
        background-color: rgb(242, 239, 239, 0.4);
        margin-top: 20px;
        padding: 20px;
        border-radius: 4px;

        .class-button{

            a{
                color: white;
                text-decoration: none;
            }
        }
    }
}